:root {
  // global
  --bg-img-login: url("../img/deks-background.svg");
  --color-theme-1: #bd0f72;
  --color-theme-2: #f0a14c;
  --color-theme-3: #8e0553;
  --color-theme-4: #c4c4c4;
  --color-theme-5: linear-gradient(
    122.05deg,
    rgba(241, 241, 241, 0.79) 19.84%,
    rgba(180, 178, 178, 0.26) 83.59%
  ) !important;
  // User
  --bg-img-kota: url("../img/background.svg");
  --bg-img-konten: url("../img/deks-background.svg");
  --bg-img-konten-mob: url("../img/mob-background.svg");

  // Display
  --bg-display: #ffffff;
  --bg-antrian-display: #dedede;
  --bg-antrian-current: #0078b7;
  --color-time-display: #00529c;
  --color-font-card1: #ffffff;
  --color-font-card2: #ffffff;
  --color-font-card3: #0078b7;
  --bg-running-text: #0078b7;
  --color-running-text: #ffffff;

  // Dashboard
  --bg-img-sidebar: url("../img/bg-side-bar-dashboard.png");

  //Login
  --ReactInputVerificationCode-itemWidth: 1em;
  --ReactInputVerificationCode-itemHeight: 1em;
  --ReactInputVerificationCode-itemSpacing: 0.5rem;

  --toastify-z-index: 9999 !important;
}
