/**
 *	Table of Content
 *
 *	# General
 *    # Alert
 *    # Analytic
 *    # Avatar
 *	  # Badge
 *		# Border
 *		# Button
 *		# Card
 *		# Color
 *		# Datepicker
 *    # Dropdown
 *		# Form
 *		# Graphic
 *		# Link
 *		# List
 *		# Loading Screen
 *    # Media
 *    # Modal
 *    # Navbar
 *    # Pagination
 *    # Progress
 *    # Rotate
 *    # Row
 *    # Shadow
 *		# Tables
 *		# Tabs
 *		# Title
 *		# Typography
 *    # Width
 */

/**
 *	# General
 */
html,
body {
	min-height: 100vh;
}
body {
	font-size: 0.875rem;
	font-family: "Poppins", sans-serif;
	letter-spacing: -0.5px;
	line-height: 1.5;
	background: #f3f3f3;
	// color: #878787;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}
#root,
.page-content {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	height: 100vh;
	overflow: hidden;
}
.site-sidebar + .site-main {
	width: calc(100vw - 80px);
	background-image: url("../img/background-content-dashboard.png");
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.2s ease-in-out;
}

.site-sidebar.open + .site-main {
	width: calc(100vw - 300px);
	background-image: url("../img/background-content-dashboard.png");
	background-repeat: no-repeat;
	background-size: cover;
}

/**
  *	# Dropdown
  */
.dropdown {
	cursor: pointer;
}
.dropdown-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dropdown-menu {
	padding: 0.5rem 0;
	margin: 0;
	border: 0;
	box-shadow: 0 2.5px 5px 0 rgba(85, 85, 85, 0.05);
}
.dropdown-header {
	font-size: 0.875rem;
	font-weight: 600;
	padding: 0.5rem 1rem;
}
.dropdown-item {
	font-size: 0.875rem;
	padding: 0.5rem 1rem;
	outline: none !important;
}
.dropdown-divider {
	margin: 0.5rem 0;
}
.dropdown-toggle:after {
	content: "\e5cc";
	font-family: "Material Icons", sans-serif;
	margin-left: 0;
	border: 0 !important;
	transform: rotate(-90deg);
}
.dropdown.show > .dropdown-toggle:after {
	transform: rotate(90deg);
}
.dropdown.show > .dropdown-menu {
	display: block;
	float: none;
}
.dropdown-menu .dropdown-toggle:after {
	display: none !important;
}

/**
  *	# Navbar
  */
.navbar {
	padding: 0.5rem 1rem;
}
.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
}
.nav-item {
	margin-bottom: 0.25rem;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}
.nav-link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: 0;
	background: transparent;
	color: #000;
	font-size: 0.875rem;
	appearance: none;
	outline: 0 !important;
	transition: all 0.3s ease-in-out;
}
.nav-link span {
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav-link .material-icons {
	font-size: 1.5rem;
	line-height: 1;
}
.navbar-text {
	padding-top: 0;
	padding-bottom: 0;
}
.nav-item .dropdown-menu {
	position: static !important;
	background: transparent;
	padding: 0;
	list-style-type: none;
	width: 100%;
	box-shadow: none;
	transform: none !important;
}
.nav-item .dropdown-menu .nav-link {
	padding-left: 2.75rem !important;
}
.nav-item .dropdown-menu .dropdown-menu .nav-link {
	padding-left: 3.75rem !important;
}
#tab-stock {
	border-radius: 20px 20px 0 0;
	width: 25%;
}
#tab-stock .nav-item {
	background-color: #acacad;
	border-radius: 20px 20px 0 0;
}
.nav-tabs#tab-stock .nav-link:hover {
	border: none;
}
#tab-stock .nav-item .nav-link.active {
	background-color: #033c59;
	color: #ffffff;
}
#tab-stock .nav-item#tab-emas .nav-link.active {
	border-radius: 20px 20px 0 0;
}
#tab-stock .nav-item#tab-berlian .nav-link.active {
	border-radius: 20px 20px 0 0;
}
